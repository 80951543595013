<template>
  <CModal
    :title="$t('delete_shipping_fee_list')"
    size="sm"
    color="danger"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <p class="h5 text-danger">{{ $t('delete_note') }}</p>
    <br>
    <p v-html="$t('delete_msg', formData)" />
    <CButton slot="footer" color="secondary" @click="modal.show = !modal.show, showUpdate()">{{ $t('cancel') }}</CButton>
    <CButton slot="footer" color="danger" @click="submit()">{{ $t('ok') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'DeleteShippingFeeModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      formData: {},
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.formData = { id: this.modal.data.id, name: this.modal.data.name }
      }
    }
  },
  computed: {
    valid_formData_id () {
      return this.formData.id ? true : false
    },
    valid_submit () {
      return this.valid_formData_id
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('destroyShippingFee', this.$_.clone(this.formData)).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('deleted_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
  },
  beforeDestroy: function () {
  },
}
</script>
