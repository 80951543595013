<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="10">
              <CSelect
                :placeholder="$t('search_a') + $t('shipping_method')"
                :options="shippingMethodsOptions"
                :value.sync="searchData.shipping_method_id"
              />
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getShippingFees()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="info" block @click="() => createShippingFeeModal = { data: { shippingMethodsOptions: shippingMethodsOptions }, modal: true }">{{ $t('create_shipping_fee_list') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <font slot="shipping_method" slot-scope="props">
              {{ props.row ? props.row.shipping_method.name : '' }}
            </font>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editShippingFeeModal = { data: $_.extend(props.row, { shippingMethodsOptions: shippingMethodsOptions }), modal: true }">{{ $t('edit') }}</CButton>
                <CButton color="success" v-bind="{ variant: 'outline' }" @click="() => editShippingFeeRegionsModal = { data: props.row, modal: true }">{{ $t('regions') }}</CButton>
                <CButton color="danger" v-bind="{ variant: 'outline' }" @click="() => deleteShippingFeeModal = { data: props.row, modal: true }">{{ $t('delete') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <CreateShippingFeeModal :data="createShippingFeeModal.data" :show="createShippingFeeModal.modal" @showUpdate="show => createShippingFeeModal.modal = show" />

      <EditShippingFeeModal :data="editShippingFeeModal.data" :show="editShippingFeeModal.modal" @showUpdate="show => editShippingFeeModal.modal = show" />

      <EditShippingFeeRegionsModal :data="editShippingFeeRegionsModal.data" :show="editShippingFeeRegionsModal.modal" @showUpdate="show => editShippingFeeRegionsModal.modal = show" />

      <DeleteShippingFeeModal :data="deleteShippingFeeModal.data" :show="deleteShippingFeeModal.modal" @showUpdate="show => deleteShippingFeeModal.modal = show" />

    </CCol>
  </CRow>
</template>

<script>
import CreateShippingFeeModal from './modal/CreateShippingFeeModal'
import EditShippingFeeModal from './modal/EditShippingFeeModal'
import EditShippingFeeRegionsModal from './modal/EditShippingFeeRegionsModal'
import DeleteShippingFeeModal from './modal/DeleteShippingFeeModal'

export default {
  name: 'methods',
  components: {
    CreateShippingFeeModal,
    EditShippingFeeModal,
    EditShippingFeeRegionsModal,
    DeleteShippingFeeModal
  },
  data () {
    return {
      columns: [ 'shipping_method', 'name', 'action' ],
      data: [],
      options: {
        headings: {
          shipping_method: this.$t('shipping_method'),
          name: this.$t('name'),
          action: this.$t('action')
        },
        sortable: [ 'shipping_method', 'name' ],
        filterable: [ 'shipping_method', 'name']
      },
      shippingMethodsOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('shipping_method') + '...' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: { shipping_method_id: null },
      createShippingFeeModal: { data: {}, modal: false },
      editShippingFeeModal: { data: {}, modal: false },
      editShippingFeeRegionsModal: { data: {}, modal: false },
      deleteShippingFeeModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
    this.getShippingMethods()
  },
  methods: {
    getShippingMethods () {
      const loader = this.$loading.show()
      this.$store.dispatch('getShippingMethods').then(res => {
        this.shippingMethodsOptions = [{ value: null, label: this.$t('please_select_a') + this.$t('shipping_method') + '...' }]
        for (const i in res) {
          this.shippingMethodsOptions.push({ value: res[i].id, label: res[i].name })
        }
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    getShippingFees () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getShippingFees', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>
