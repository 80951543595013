<template>
  <CModal
    :title="$t('edit_shipping_fee_list_regions')"
    color="success"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('shipping_method')"
          :placeholder="$t('shipping_method')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.shipping_method.name"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('name')"
          :placeholder="$t('name')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.name"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('regions') }}
          </label>
          <div class="col-sm-9">
            <multiselect
              v-model="formData.regionsSelect"
              :placeholder="$t('regions')"
              label="label"
              track-by="value"
              :options="regionsOptions"
              :multiple="true"
              :show-labels="false"
              :close-on-select="false"
              :max-height="1000"
            />
          </div>
        </div>
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'EditShippingFeeRegionsModal',
  components: {
    Multiselect
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      regionsOptions: [],
      formData: { shipping_method: { name: '' } },
      rows: 1
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.getRegions(() => {
          this.showShippingMethod()
        })
      }
    }
  },
  computed: {
    valid_submit () {
      return true
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    showShippingMethod () {
      const loader = this.$loading.show()
      this.$store.dispatch('showShippingMethod', { id: this.modal.data.shipping_method_id }).then(res => {
        const regions = this.$_.pluck(res[0].shipping_method_region_relations, 'region_id')
        this.regionsOptions = this.$_.filter(this.regionsOptions, option => regions.includes(option.value))
        this.formData = this.$_.extend(this.$_.clone(this.modal.data), { regions: this.$_.pluck(this.$_.filter(res[0].shipping_method_region_relations, v => v.shipping_fee_id === this.modal.data.id), 'region_id') })
        this.formData = this.$_.extend(this.$_.clone(this.formData), { regionsSelect: this.$_.filter(this.regionsOptions, option => this.formData.regions.includes(option.value)) })
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    getRegions (cb) {
      const loader = this.$loading.show()
      this.$store.dispatch('getRegions').then(res => {
        this.regionsOptions = []
        for (const i in res) {
          this.regionsOptions.push({ value: res[i].id, label: this.$store.getters.locale === 'tw' ? res[i].zh_name : res[i].en_name })
        }
        loader.hide()
        cb(true)
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
        cb(false)
      })
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateShippingFeeRegions', this.$_.extend(this.$_.clone(this.formData), { regions: this.$_.pluck(this.formData.regionsSelect, 'value') })).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
  },
  beforeDestroy: function () {
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
