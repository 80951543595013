<template>
  <CModal
    :title="$t('edit_shipping_fee_list')"
    size="xl"
    color="warning"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('shipping_method')"
          :options="shippingMethodsOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="formData.shipping_method_id"
          :isValid="valid_formData_shipping_method_id"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('name')"
          :placeholder="$t('name')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.name"
          :isValid="valid_formData_name"
        />
      </CCol>
    </CRow>
    <hr>
    <CRow>
      <CCol col="12" sm="8" lg="9" xl="10">
      </CCol>
      <CCol col="12" sm="4" lg="3" xl="2">
        <CInput
          placeholder="rows"
          type="number"
          v-model="rows"
        >
          <CButton color="info" size="sm" slot="append" @click="addRows()">{{ $t('add_rows') }}</CButton>
        </CInput>
      </CCol>
    </CRow>
    <CRow v-for="(row, index) in formData.shipping_fee_settings" v-bind:key="index">
      <CCol col="12" sm="12"><hr class="mt-0"></CCol>
      <CCol col="12" sm="3">
        <CInput
          :label="$t('from_weight')"
          :placeholder="$t('from_weight')"
          type="number"
          step="0.01"
          v-model="row.from_weight"
        />
      </CCol>
      <CCol col="12" sm="3">
        <CInput
          :label="$t('to_weight')"
          :placeholder="$t('to_weight')"
          type="number"
          step="0.01"
          v-model="row.to_weight"
        />
      </CCol>
      <CCol col="12" sm="3">
        <CInput
          :label="$t('size')"
          :placeholder="$t('size')"
          type="number"
          step="0.01"
          v-model="row.size"
        />
      </CCol>
      <CCol col="12" sm="2">
        <CInput
          :label="$t('shipping_fee')"
          :placeholder="$t('shipping_fee')"
          type="number"
          step="0.01"
          v-model="row.fee"
        />
      </CCol>
      <CCol col="12" sm="1" class="d-flex justify-content-end align-items-end">
        <CButton
          color="danger"
          v-bind="{ variant: 'outline', shape: 'pill' }"
          @click="delRows(index)"
          style="margin: 0px 0px 16px;"
          :class="{ 'disabled-cursor': formData.shipping_fee_settings.length <= 1 }"
          :disabled="formData.shipping_fee_settings.length <= 1"
        >
          <CIcon name="cil-trash" />
        </CButton>
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<style>
  .disabled-cursor {
    cursor: not-allowed;
  }
</style>

<script>
export default {
  name: 'EditShippingFeeModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      shippingMethodsOptions: [],
      formData: { shipping_fee_settings: [] },
      rows: 1
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
      this.shippingMethodsOptions = e.shippingMethodsOptions
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.showShippingFee()
      }
    }
  },
  computed: {
    valid_formData_shipping_method_id () {
      return this.formData.shipping_method_id ? true : false
    },
    valid_formData_name () {
      return this.formData.name ? true : false
    },
    valid_formData_from_weight () {
      return this.$_.every(this.formData.shipping_fee_settings, v => /^[0-9]+(.[0-9]+)?$/.test(v.from_weight))
    },
    valid_formData_to_weight () {
      return this.$_.every(this.formData.shipping_fee_settings, v => /^[0-9]+(.[0-9]+)?$/.test(v.to_weight))
    },
    valid_formData_size () {
      return this.$_.every(this.formData.shipping_fee_settings, v => /^[0-9]+(.[0-9]+)?$/.test(v.size))
    },
    valid_formData_fee () {
      return this.$_.every(this.formData.shipping_fee_settings, v => /^[0-9]+(.[0-9]+)?$/.test(v.fee))
    },
    valid_submit () {
      return this.valid_formData_shipping_method_id && this.valid_formData_name && this.valid_formData_from_weight && this.valid_formData_to_weight && this.valid_formData_size && this.valid_formData_fee
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    showShippingFee () {
      const loader = this.$loading.show()
      this.$store.dispatch('showShippingFee', { id: this.modal.data.id }).then(res => {
        this.formData = res[0]
        this.formData.shipping_fee_settings = this.$_.sortBy(this.formData.shipping_fee_settings, 'fee')
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateShippingFee', this.$_.clone(this.formData)).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
    addRows () {
      for (let i = 0; i < this.rows; i ++) {
        this.formData.shipping_fee_settings.push({ from_weight: 0, to_weight: 0, size: 0, fee: 0 })
      }
    },
    delRows (idx) {
      this.formData.shipping_fee_settings.splice(idx, 1)
    },
  },
  beforeDestroy: function () {
  },
}
</script>
